import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from "./pages/Login"
import HomePage from "./pages/Home"
import ProfilePage from "./pages/Profile"
import OrderPage from "./pages/Orders"
import OrderDetailPage from "./pages/Order"
import LicensePage from "./pages/Licenses"
import DocumentPage from "./pages/Documents"
import WalletPage from "./pages/Wallet"
import NotFoundPage from "./pages/NotFoundPage"
import VerifyEmailPage from "./pages/VerifyEmail"
import RegistrationPage from "./pages/Registration"
import GeneologyPage from "./pages/Geneology"
import ApiLogin from "./pages/ApiLogin"

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}  />
          <Route path="/:id" element={<VerifyEmailPage />}  />
          <Route path="/home" element={<HomePage />}  />
          <Route path="/register" element={<RegistrationPage />}  />
          <Route path="/profile" element={<ProfilePage />}  />
          <Route path="/orders" element={<OrderPage />}  />
          <Route path="/order/:id" element={<OrderDetailPage />} />
          <Route path="/licenses" element={<LicensePage />}  />
          <Route path="/documents" element={<DocumentPage />}  />
          <Route path="/wallet" element={<WalletPage />}  />
          <Route path="/geneology" element={<GeneologyPage />}  />
          <Route path="/apilogin" element={<ApiLogin />}  />
          <Route path="*" element={<NotFoundPage />}  />

        </Routes>
      </BrowserRouter>
  );
}

export default App;
