import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";

export const ReferralLink = (props) => {

    const profile = props.data;
    const [isCopied, setIsCopied] = useState(false);

    
  const copyToClipboard = (e) => {

    console.info()
    copy(e.currentTarget.textContent);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

    return (<div className="main-content-body tab-pane  active" id="referral">
    <div className="card mb-2">
      <div className="card-body">
        <div className="mb-4 main-content-label">
          My Referral link
        </div>
        <div className="form-group col-lg-5">
          <p className="form-control cpn referral-link"
            id="referral_link"
            onClick={copyToClipboard} style={{ overflow: "hidden" }}>
            {profile.referral_link}
          </p>
          <p id="copied_code" style={{color:"green"}}>{isCopied ? 'Copied!' : ''}</p>
        </div>
      </div>
    </div>
  </div>);
}
export default ReferralLink