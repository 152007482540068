import React, { useState } from 'react';
import axiosBaseURL from '../Auth/httpCommon';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ChangePassword = (props) => {

    const [message, setMessage] = useState();
    const [isMessage, setIsMessage] = useState(false);
    const [isDisable, setIsDisable] = useState(false);


    const [formInput, setFormInput] = useState({
        password: "",
        confirmPassword: "",
      });
    
      const [formError, setFormError] = useState({
        password: "",
        confirmPassword: "",
      });

      const handleUserInput = (name, value) => {
        setFormInput({
          ...formInput,
          [name]: value,
        });
      };

      const validateFormInput = (event) => {
        event.preventDefault();
        let inputError = {
          password: "",
          confirmPassword: "",
        };
    
        if (!formInput.password) {
          setFormError({
            ...inputError,
            password: "Password should not be empty",
          });
          return
        }
    
        if (formInput.confirmPassword !== formInput.password) {
          setFormError({
            ...inputError,
            confirmPassword: "Password and confirm password should be same",
          });
          return;
        }
    
    
        setFormError(inputError);

        console.info("Form Done");

            setIsDisable(true);
            const employee = {
                email: props.data,
                password: formInput.password,
            }
            axiosBaseURL.put('api/change-password', employee)
            .then((response) => {
            let resData = response.data;
            console.info(resData)
            if (resData.status === 1) {
                console.info(resData.data);
                setMessage("Password has been changed successfully");
                toast("Password updated successfully.");
            } else {
                setMessage(resData.message);
                console.error(resData.message);
                toast("Password not updated, try later.");
            }
            });

            setIsDisable(false)
            setIsMessage(true)

            setFormInput({
                password: "",
                confirmPassword: "",
              })

      };

    return (
        <form
        name="change_password"
        id="change_password"
        onSubmit={validateFormInput}>
        <div className="form-group ">
          <label className="required">
            New password
          </label>
          <input
            className="form-control"
            type="password"
            name="password"
            value={formInput.password}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}/>
            <p className="error-message text-danger">{formError.password}</p>
        </div>
        <div className="form-group">
          <label className="required" >
            Repeat New password
          </label>
          <input
            className="form-control"
            type="password"
            name="confirmPassword"
            value={formInput.confirmPassword}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}
          />
            <p className="error-message text-danger">{formError.confirmPassword}</p>

        </div>
        <div className="form-group" align="right">
          <button className="btn btn-primary" type="submit" >
            Save
          </button>
        </div>
      </form>
    );
}

export default ChangePassword