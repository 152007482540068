import React from "react";
const Paginate = ({ pageNumbers, nPage, currentPage, setCurrentPage, nextPage, prevPage }) => {

  return (
    <nav aria-label="Page navigation ">
      <ul className="pagination " style={{float:"right"}}>
        <li className="page-item">
          <a href="#" className="page-link" onClick={prevPage}>
            Prev
          </a>
        </li>
        {pageNumbers.map((pgNumer) => (
          <li
            key={pgNumer}
            className={`page-item ${currentPage === pgNumer ? "active" : ""}`}>
            <a
              href="#"
              className="page-link"
              onClick={() => setCurrentPage(pgNumer)}>
              {pgNumer}
            </a>
          </li>
        ))}
        <li className="page-item">
          <a href="#" className="page-link" onClick={nextPage}>
            Next
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Paginate;
