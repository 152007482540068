import { render } from "@testing-library/react"


const AuthLayout = ({ children }) => {

  return (
          <main className="bg-prime">{children}</main>
  ); 
    
}

export default AuthLayout