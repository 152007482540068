import React,{useEffect,useState} from "react";
import { Link, useLocation } from "react-router-dom";


export const Sidebar = () => {

    const [isActive, setIsActive] = useState(false);
    const location = useLocation();
    const pathnameParts = location.pathname.split("/").filter(Boolean);

    const showStyle = {
        display: "block"
      };
      const hideStyle = {
        display: "none"
      };

    const accountmenu = () => {
        setIsActive(current => !current);
      }

      useEffect(() => {
      console.info(pathnameParts[0])
      if(pathnameParts[0] === 'profile'){
          setIsActive(true)
      }else if(pathnameParts[0] === 'orders'){
        setIsActive(true)
    }else if(pathnameParts[0] === 'licenses'){
        setIsActive(true)
    }else if(pathnameParts[0] === 'wallet'){
            setIsActive(true)
    }else if(pathnameParts[0] === 'geneology'){
        setIsActive(true)
    }
    });

    return (
        <div className="sticky sticky-pin">
    <aside className="app-sidebar ps ps--active-y">
        <div className="main-sidebar-header active">
            <a className="header-logo active" href="/home">
                <img src={process.env.PUBLIC_URL + 'assets/img/brand/logo.png'} className="main-logo  desktop-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + 'assets/img/brand/logo-white.png'} className="main-logo  desktop-dark" alt="logo" />
                <img src={process.env.PUBLIC_URL + 'assets/img/brand/favicon.png'} className="main-logo  mobile-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + 'assets/img/brand/favicon-white.png'} className="main-logo  mobile-dark" alt="logo" />
            </a>
        </div>

        <div className="main-sidemenu is-expanded " style={{ marginLeft: "0px"}}>
            <ul className="side-menu open" style={{ marginLeft: "0px", marginRight: "0px"}}>
                <li className="slide navbarLogo custom-sidebar-margin-height" style={{ height: "57px"}} >
                    <a className="side-menu__item" href="/">
                    </a>
                </li>
                
                 <li className="slide">
                    <a className="side-menu__item" target="_blank" href="http://marketplace.warriortradingbot.com/customer/account/login?p=22">
                        <svg xmlns="http://www.w3.org/2000/svg"  className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M20 7h-1.209A4.92 4.92 0 0 0 19 5.5C19 3.57 17.43 2 15.5 2c-1.622 0-2.705 1.482-3.404 3.085C11.407 3.57 10.269 2 8.5 2 6.57 2 5 3.57 5 5.5c0 .596.079 1.089.209 1.5H4c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2zm-4.5-3c.827 0 1.5.673 1.5 1.5C17 7 16.374 7 16 7h-2.478c.511-1.576 1.253-3 1.978-3zM7 5.5C7 4.673 7.673 4 8.5 4c.888 0 1.714 1.525 2.198 3H8c-.374 0-1 0-1-1.5zM4 9h7v2H4V9zm2 11v-7h5v7H6zm12 0h-5v-7h5v7zm-5-9V9.085L13.017 9H20l.001 2H13z"/></svg><span className="side-menu__label">Marketplace</span>
                    </a>
                </li>
                {/* <li className="slide">
                    <a className="side-menu__item "  href="#">
                        <i className="fe fe-user-check side-menu__icon1 "></i>
                        <span className="side-menu__label"> &nbsp;&nbsp;Follow Expert</span>
                    </a>
                </li> */}
                
                <li className="slide">
                    <a className="side-menu__item " href="#">
                        <svg xmlns="http://www.w3.org/2000/svg"  className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm11-6h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 4H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6H5v-4h4v4zm8-6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"/></svg><span className="side-menu__label">My Referrals</span>
                    </a>
                </li>

                <li className="slide" id="my-account-menu-li">

                {/* <NavLink className="side-menu__item" data-bs-toggle="slide" to="#" onClick={e => e.preventDefault()} > 
                    <svg xmlns="http://www.w3.org/2000/svg" className="header-icon-svgs" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path><path d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z"></path></svg><span className="side-menu__label">&nbsp;&nbsp; My Account</span>
                        <i className="angle fe fe-chevron-right"></i>
                </NavLink> */}

                    <a className="side-menu__item" id="my-account-menu" data-bs-toggle="slide" href="#" onClick={accountmenu}  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="header-icon-svgs" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path><path d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z"></path></svg><span className="side-menu__label">&nbsp;&nbsp; My Account</span>
                        <i className="angle fe fe-chevron-right"></i>
                        </a>
                    <ul  className="slide-menu" style={isActive ? showStyle : hideStyle}>
                        <li className={pathnameParts[0] == "profile" ? "is-expanded" : ''}><a className={pathnameParts[0] == "profile" ? "slide-item active" : 'slide-item'} href="/profile" activeclassname="active">My Profile</a></li>
                        <li className={pathnameParts[0] == "orders" ? "is-expanded" : ''}><a className={pathnameParts[0] == "orders" ? "slide-item active" : 'slide-item'} href="/orders">My Orders</a></li>
                        <li className={pathnameParts[0] == "licenses" ? "is-expanded" : ''}><a className={pathnameParts[0] == "licenses" ? "slide-item active" : 'slide-item'} href="/licenses">My Licenses</a></li>
                        {/* <li><a className="slide-item " href="/documents">My documents</a></li> */}
                        <li className={pathnameParts[0] == "wallet" ? "is-expanded" : ''}><a className={pathnameParts[0] == "wallet" ? "slide-item active" : 'slide-item'} href="/wallet">My Wallet</a></li>
                        <li className={pathnameParts[0] == "geneology" ? "is-expanded" : ''}>
                            <a className={pathnameParts[0] == "geneology" ? "slide-item active" : 'slide-item'} href="/geneology">
                               My Geneology
                            </a>
                        </li>
                            {/* <li><a className="slide-item" href="/">Go to Admin</a></li> */}
                            {/* <li><a className="slide-item" href="/">Go to Seller</a></li> */}
                        
                    </ul>
                </li>

                <li className="slide">
                    <a className="side-menu__item"  href="http://my.signinonce.com/logout?application=utit_react" >
                        <i className="fe fe-skip-back side-menu__icon1" ></i>
                        <span className="side-menu__label">&nbsp;&nbsp; Logout</span>
                    </a>
                    
                </li>
                {/* @endif */}

            </ul>

        </div>
    </aside>
</div>
    );
};