const Navigation = () => {
  return (
    <div className="main-header side-header sticky nav nav-item">
      <div className=" main-container container-fluid">
        <div className="main-header-left ">
          {/* <?php
            $host = request()->getHttpHost();
            if ($host != 'cashback.utradeitrade.com' && $host != 'nexi.utradeitrade.com'):

                $announcement = \Auth::user()->userUserAlerts()
                    ->where(['show_hide' => 1])
                    ->whereRaw("find_in_set('2',type)")->get();
            endif;
            ?> */}
          <div className="responsive-logo">
            <a href="/home" className="header-logo">
              <img
                src={process.env.PUBLIC_URL + "images/logo.png"}
                className="mobile-logo logo-1"
                alt="logo"
              />
            </a>
          </div>

          <div className="left-content">
            <div className="justify-content-center mt-2 d-inline-block">
              <ol className=" home_top_menu mb-2">
                <li className="tx-15">
                  {/* @if($host == 'cashback.utradeitrade.com') */}
                  <a className="side-menu__itemTop" href="/home">
                    <i className="fe fe-home side-menu__icon"></i>
                    <span className="side-menu__labelTop">Dashboard</span>
                  </a>
                  {/* @else */}
                  {/* <a className="side-menu__itemTop" href="{{ route('frontend.home') }}">
                                    <i className="fe fe-home side-menu__icon"></i>
                                    <span className="side-menu__labelTop">{{ __('Dashboard') }}</span>
                                </a> */}
                  {/* @endif */}
                </li>
                <li className="tx-15">
                  <a href="#" className="side-menu__itemTop">
                    <i className="fe fe-help-circle side-menu__icon"></i>
                    <span className="side-menu__labelTop">Help</span>
                  </a>
                </li>
              </ol>
            </div>
          </div>
          <div className="main-header-right">
            <div className="mb-0 navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <ul className="nav nav-item header-icons navbar-nav-right ms-auto">
                  <li className="dropdown nav-item main-header-notification d-flex">
                    {/* <a
                      className="new nav-link"
                      data-bs-toggle="dropdown"
                      href="/"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="header-icon-svgs"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" />
                      </svg>
                      <span className=" pulse"></span>
                    </a> */}
                    {/* @if ($host != 'cashback.utradeitrade.com' && $host != 'nexi.utradeitrade.com')
                                    <div className="dropdown-menu">
                                        @if(!$announcement->isEmpty())
                                            <div className="menu-header-content text-start border-bottom">
                                                <div className="d-flex">
                                                    <h6 className="dropdown-title mb-1 tx-15 font-weight-semibold">
                                                        Notifications</h6>
                                                </div>
                                                <p className="dropdown-title-text subtext mb-0 op-6 pb-0 tx-12 ">You
                                                    have {{$announcement->count()}} unread Notifications</p>
                                            </div>
                                            <div className="main-notification-list Notification-scroll">

                                                @foreach($announcement as $anc)
                                                    <a className="d-flex p-3 border-bottom" href="#">
                                                        <div className="notifyimg heightFix bg-pink">
                                                            <i className="far fa-envelope-open text-white"></i>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h5 className="notification-label mb-1">{{$anc->alert_text}}</h5>
                                                            <div className="notification-subtext">{{ date("M d, Y", strtotime($anc->created_at)) }}</div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <i className="las la-angle-right text-end text-muted"></i>
                                                        </div>
                                                    </a>
                                                @endforeach

                                            </div>
                                    @endif
                                    </div>
                                @endif */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="app-sidebar__toggle" data-bs-toggle="sidebar">
            <a className="open-toggle" href="/">
              <i className="header-icon fe fe-align-left"></i>
            </a>
            <a className="close-toggle" href="/">
              <i className="header-icon fe fe-x"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
