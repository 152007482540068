import AuthLayout from "../components/Layout/AuthLayout";
import {
  Routes,
  Switch,
  BrowserRouter as Router,
  Route,
  Link,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../components/Loader";

function ApiLogin() {
  const [api, setApi] = useState();
  const [isRedirect, setIsRedirect] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const search = useLocation().search;
  var email2 = new URLSearchParams(search).get("email");
  if (email2 === null) {
    email2 = JSON.parse(localStorage.getItem("email") || "") || null;
  }
  var token2 = new URLSearchParams(search).get("token");
  if (token2 === null) {
    token2 = JSON.parse(localStorage.getItem("token") || "") || null;
  }

  const requestOptions = { email: email2, token: token2 };

  const headers = {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "base-token": "ab40db25fc6bde0eb707f68b8184d57c",
  };

  useEffect(() => {
    axios
      .post("https://utradeitrade.puxeo.com/api/utit-login", requestOptions, {
        headers,
      })
      .then((response) => {
        let resData = response.data;
        if (resData.status === 1) {
          setApi(resData.data);
          setLoading(false);
          setIsRedirect(true);
        } else {
          console.error(resData.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (isRedirect) {
    return <Navigate replace to={'/home?email='+email2+'&token='+token2} />;
  }
}

export default ApiLogin;
