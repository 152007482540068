import Layout from "../components/Layout/Layout";
import { useEffect, useState, useMemo } from "react";
import axiosBaseURL from "../components/Auth/httpCommon";
import axios from "axios";
import { Routes, Route, Link,useLocation  } from "react-router-dom";
import CountrySelector from "../components/CountrySelector"
import ChangePassword from "../components/Profile/ChangePassword";
import Subscription from "../components/Profile/Subscription";
import ReferralLink from "../components/Profile/ReferralLink";
import Select from 'react-select'
import countryList from 'react-select-country-list'
// import DatePicker from "../utils/ReactDatePicker";
// import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import Loader from "../components/Loader";
import "../css/react-datepicker.css";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProfilePage() {
  const [profile, setProfile] = useState();
  const [isLoading, setLoading] = useState(true);
  const [defaultSelect, setDefaultSelect] = useState();
  const location = useLocation();
  const [startDate, setStartDate] = useState(new Date());
  const options = useMemo(() => countryList().getData(), [])
  const dateOptions = { year: "numeric", month: "numeric", day: "numeric" };
  const [imageFile, setImageFile] = useState();
  const [profileImage, setProfileImage] = useState();

  var token2 = new URLSearchParams().get("token");
  if (token2 === null) {
    token2 = JSON.parse(localStorage.getItem("token") || "") || null;
  }

    // to set and get values from the jsx
    const [formInput, setFormInput] = useState({
      name: ""
    });

    const [formError, setFormError] = useState({
      name: ""
    });

    const handleUserInput = (name, value) => {
      setFormInput({
        ...formInput,
        [name]: value
      });
    };

    const [message, setMessage] = useState();
    const [isMessage, setIsMessage] = useState(false);
    const [isDisable, setIsDisable] = useState(false);


  let email = JSON.parse(localStorage.getItem('email') || '') || null;

  const colorGreen = {
    color: "green",
  };

  const imgMargin = {
    margin: "20px 20px 20px 0",
    width: "100px !important",
  };

  const handelSubmit = (e) => {
    setIsDisable(true);
    e.preventDefault();

    let inputError = {
      name: "",
    };

    // name validation
    if (!formInput.name) {
      setFormError({
        ...inputError,
        name: "Name should not be empty",
      });
      return
    }

    var bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("name", formInput.name);
  
    bodyFormData.append("first_name", formInput.first_name);
    bodyFormData.append("middle_name", formInput.middle_name);
    bodyFormData.append("last_name", formInput.last_name);
    bodyFormData.append("phone", formInput.phone);
    bodyFormData.append("date_of_birth", moment(startDate).format("DD-MM-YYYY"));
    bodyFormData.append("building_num", formInput.building_num);
    bodyFormData.append("street", formInput.street);
    bodyFormData.append("region", formInput.region);
    bodyFormData.append("postcode", formInput.postcode);
    bodyFormData.append("city", formInput.city);
    bodyFormData.append("business_name", formInput.business_name);
    bodyFormData.append("vat_number", formInput.vat_number);
    bodyFormData.append("country", formInput.country);
    bodyFormData.append("country_id", formInput.country);
    bodyFormData.append("image", imageFile);

    // axiosBaseURL.post('api/edit-user', employee)
    axios.post("https://utradeitrade.puxeo.com/api/edit-user", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-date",
        "base-token": "ab40db25fc6bde0eb707f68b8184d57c",
        "user-token": token2,
      }
    }).then((response) => {
      let resData = response.data;
      if (resData.status === 1) {
        window.location.reload();
        toast("Profile saved successfully!");
        // setMessage("User updated successfully");
      } else {
        setMessage(resData.message);
        console.error(resData.message);
        toast("Profile update unsuccessful. Please try again later.");
      }
      setIsDisable(false)
      setIsMessage(true)
    });
    setIsDisable(false)
    setIsMessage(true)
}
  useEffect(() => {
    let requestParam = {email: email}
    axiosBaseURL.get('api/fetch-user',{ params: requestParam })
      .then(response => {
        let resData = response.data;
        if(resData.status === 1){
          setProfile(resData.data);
          setProfileImage(resData.data.image);
          setFormInput({
            name:resData.data.name,
            first_name:resData.data.first_name,
            middle_name:resData.data.middle_name,
            last_name:resData.data.last_name,
            phone:resData.data.phone,
            date_of_birth:resData.data.date_of_birth,
            building_num:resData.data.building_num,
            street:resData.data.street,
            region:resData.data.region,
            postcode:resData.data.postcode,
            city:resData.data.city,
            business_name:resData.data.business_name,
            vat_number:resData.data.vat_number,
            country:resData.data.country,
          });
          setStartDate(new Date(resData.data.date_of_birth));
          setLoading(false);

        }else{
          console.error(resData.message);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);


  options.forEach((element,i) => {
      if(element.label == formInput.country){
        setDefaultSelect(i);
        formInput.country = options[i].value;
        // setValue(options[defaultSelect]);
      }
  });
  
  const filterChangeHandler = (selectedYear) => {
    formInput.country = selectedYear.value;
  };
  
  function handleChange(event) {
    setImageFile(event.target.files[0]);
  }
 

  if (isLoading) {
    return <Loader/>;
  }
  
  return (
    <Layout>
      <ToastContainer />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card custom-card">
            <div className="card-body d-md-flex">
              <div className="">
                <span className="profile-image pos-relative">
                  <img className="br-5" alt="profile" src={profile.image} />
                  <span className="bg-success text-white wd-1 ht-1 rounded-pill profile-online"></span>
                </span>
              </div>
              <div className="my-md-auto mt-4 prof-details">
                <h4 className="font-weight-semibold ms-md-4 ms-0 mb-1 pb-0">
                  {profile.name}
                </h4>
                <p className="tx-13 text-muted ms-md-4 ms-0 mb-2 pb-2 ">
                  {/* {profile.region ? (
                    <span className="me-3">
                    <i className="fa fa-taxi me-2"></i>{profile.region} ,
                </span>
                  )
                  : ''} */}

{profile.country ? (
                    <span><i className="far fa-flag me-2"></i>{profile.country}</span>
                  )
                  : ''}
                  
                  {/* @if(!empty($user->region))
                            <span className="me-3">
                                <i className="fa fa-taxi me-2"></i>{{$user->region }}@if(!empty($user->region)) , @endif{{ $user->city}}
                            </span>
                            @endif
                            @if(!empty($user->country_id))<span><i className="far fa-flag me-2"></i>{{$country_name}}</span>@endif */}
                </p>
                <p className="text-muted ms-md-4 ms-0 mb-2">
                  <span>
                    <i className="fa fa-phone me-2"></i>
                  </span>
                  <span className="font-weight-semibold me-2">Phone:</span>
                  <span>{profile.phone}</span>
                </p>
                <p className="text-muted ms-md-4 ms-0 mb-2">
                  <span>
                    <i className="fa fa-envelope me-2"></i>
                  </span>
                  <span className="font-weight-semibold me-2">Email:</span>
                  <span>{profile.email}</span>
                </p>
                <p className="text-muted ms-md-4 ms-0 mb-2">
                  <span>
                    <i className="fa fa-globe me-2"></i>
                  </span>
                  <span className="font-weight-semibold me-2">Sponsor:</span>
                  <span>{profile.sponsor_name}</span>
                </p>
              </div>
            </div>
            <div className="card-footer py-0">
              <div className="profile-tab tab-menu-heading border-bottom-0">
                <nav className="nav main-nav-line p-0 tabs-menu profile-nav-line border-0 br-5 mb-0	">
                  <a
                    className="nav-link  mb-2 mt-2 active"
                    data-bs-toggle="tab"
                    href="#referral"
                  >
                    Referral link
                  </a>
                  <a
                    className="nav-link mb-2 mt-2"
                    data-bs-toggle="tab"
                    href="#edit"
                  >
                    Edit Profile
                  </a>
                  <a
                    className="nav-link  mb-2 mt-2"
                    data-bs-toggle="tab"
                    href="#subscription"
                  >
                    Current Subscription
                  </a>
                  <a
                    className="nav-link  mb-2 mt-2"
                    data-bs-toggle="tab"
                    href="#changepassword"
                  >
                    Change password
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12">
          <div className="custom-card main-content-body-profile">
            <div className="tab-content">
              <ReferralLink data={profile} />
              
              <div className="main-content-body border tab-pane border-top-0"
                id="edit">
                <div className="card">
                  <div className="card-body">
                    <div className="mb-4 main-content-label">my profile</div>
                    <form method="POST"
                      name="profile_update"
                      id="profile_update"
                      encType="multipart/form-data">
                      <input type="hidden" name="email" id="email" defaultValue="email"/>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-md-3">
                            <label className="form-label required" >
                              Full Name
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              className="form-control"
                              type="text"
                              name="name"
                              id="name"
                              defaultValue={formInput.name}
                              // value={formInput.name}
                              onChange={({ target }) => {
                                handleUserInput(target.name, target.value);
                              }}
                            />
                            <p className="error-message text-danger">{formError.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-md-3">
                            <label className="form-label" >
                              Country
                            </label>
                          </div>
                          <div className="col-md-9">
                            {/* <select
                              className="form-control select2 {{ $errors->has('country') ? 'is-invalid' : '' }}"
                              name="country_id"
                              id="country_id"
                            > */}
                            <Select options={options} name="country_id" defaultValue={options[defaultSelect]}  onChange={filterChangeHandler}  />
                              {/* <CountrySelector data={formInput.country} onChangeFilter={filterChangeHandler} defaultValue={formInput.country} /> */}
                              {/* @foreach($countries as $id => $entry)
                                                        <option defaultValue="{{ $id }}" {{ (old('country_id') ? old('country_id') : $user->country->id ?? '') == $id ? 'selected' : '' }}>{{ $entry }}</option>
                                                    @endforeach */}
                            {/* </select> */}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-md-3">
                            <label className="form-label">
                              Phone
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              className="form-control "
                              type="text"
                              name="phone"
                              id="phone"
                              defaultValue={formInput.phone}
                              onChange={({ target }) => {
                                handleUserInput(target.name, target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-md-3">
                            <label className="form-label" >
                              Birth Date
                            </label>
                          </div>
                          <div className="col-md-9">
                          <DatePicker 
                          dateFormat="dd/MM/yyyy"
                          defaultDate={startDate}
                          selected={startDate}
                          className="form-control" 
                          name="date_of_birth"
                          onChange={(date) => setStartDate(date)}
                           />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-md-3">
                            <label className="form-label" >
                              Business Name
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              className="form-control "
                              type="text"
                              name="business_name"
                              id="business_name"
                              defaultValue={formInput.business_name}
                              // value={formInput.business_name}
                                onChange={({ target }) => {
                                  handleUserInput(target.name, target.value);
                                }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-md-3">
                            <label className="form-label" >
                              Vat Number
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              className="form-control {{ $errors->has('vat_number') ? 'is-invalid' : '' }}"
                              type="text"
                              name="vat_number"
                              id="vat_number"
                              defaultValue={formInput.vat_number}
                              // value={formInput.vat_number}
                            onChange={({ target }) => {
                              handleUserInput(target.name, target.value);
                            }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-md-3">
                            <label className="form-label" >
                              Upload Profile picture
                            </label>
                          </div>
                          <div className="col-md-9">
                            { profileImage !== null}
                            <img
                              src={profileImage}
                              className="selectImage custom-width-150"
                              alt="User"
                              style={imgMargin}
                            />
                            
                            <input
                              id="pro-image"
                              type="file"
                              className="inputMaterial form-control "
                              name="file"
                              onChange={handleChange}
                            />
                            <input
                              type="hidden"
                              id="proimage"
                              name="proimage"
                              defaultValue="image"
                            />
                            <span className="bar"></span>
                          </div>
                        </div>
                      </div>
                      <div className="mb-4 main-content-label">
                        Primary Address
                      </div>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-md-3">
                            <label className="form-label">
                              Building Number
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              className="form-control"
                              type="text"
                              name="building_num"
                              id="building_num"
                              defaultValue={formInput.building_num}
                              onChange={({ target }) => {
                                handleUserInput(target.name, target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-md-3">
                            <label className="form-label" >
                              street
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              className="form-control "
                              type="text"
                              name="street"
                              id="street"
                              defaultValue={formInput.street}
                              onChange={({ target }) => {
                                handleUserInput(target.name, target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-md-3">
                            <label className="form-label" >
                              region
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              className="form-control "
                              type="text"
                              name="region"
                              id="region"
                              defaultValue={formInput.region}
                              onChange={({ target }) => {
                                handleUserInput(target.name, target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-md-3">
                            <label className="form-label" >
                              postcode
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              className="form-control {{ $errors->has('postcode') ? 'is-invalid' : '' }}"
                              type="text"
                              name="postcode"
                              id="postcode"
                              defaultValue={formInput.postcode}
                              onChange={({ target }) => {
                                handleUserInput(target.name, target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row row-sm">
                          <div className="col-md-3">
                            <label className="form-label" >
                              city
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              className="form-control {{ $errors->has('city') ? 'is-invalid' : '' }}"
                              type="text"
                              name="city"
                              id="city"
                              defaultValue={formInput.city}
                              onChange={({ target }) => {
                                handleUserInput(target.name, target.value);
                              }}
                              />
                          </div>
                        </div>
                      </div>
                      <div className="form-group" align="right">
                        <button className="btn btn-primary" type="submit" onClick={e => handelSubmit(e)}>
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              
              <Subscription data={profile.subscription} />
              
              <div
                className="main-content-body  border tab-pane border-top-0"
                id="changepassword">
                <div className="card mb-2">
                  <div className="card-body">
                    <div className="mb-4 main-content-label">
                      Change password
                    </div>
                    <ChangePassword data={email}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div
        className="modal fade"
        id="upgradeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="upgradeModalTitle"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form
              method="POST"
              name="upgrade_platform"
              id="upgrade_platform"
              action="#">
              <div className="modal-header">
                <h5 className="modal-title" id="upgradeModalTitle">
                  Upgrade Platform
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <select
                  required
                  className="form-control required upgrade-platform custom-width-100"
                  name="bot_price"
                  id="botPrice"
                  tabIndex="-1"
                  aria-hidden="true">
                  {/* @if(!empty($final_price)) @foreach($final_price as
                  $key=>$value) */}
                  <option defaultValue="value">up to 1 bots / €1 for platform</option>
                  {/* @endforeach @endif */}
                </select>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Add to Cart
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ProfilePage;
