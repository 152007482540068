import React from 'react';
import { Link } from 'react-router-dom';
import PageNotFound from '../assets/image/pagenotfound.jpg';
class NotFoundPage extends React.Component{
    render(){
        return <div className='text-center'>
            <img src={PageNotFound} alt='Not Found' />
            <p style={{textAlign:"center"}}>
              <Link className='btn btn-primary' style={{margin:"20px"}} to="/">Go to Home </Link>
            </p>
          </div>;
    }
}
export default NotFoundPage;