import AuthLayout from '../components/Layout/AuthLayout'
import { useEffect } from 'react';
// import '../css/SIO/main.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {

    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('bg-primary');
      });

return (
  <AuthLayout>
    <ToastContainer />
    <div className="page-single">
        <div className="container">
            <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main mx-auto my-auto py-45 justify-content-center">
                    <div className="card-sigin mt-5 mt-md-0">
                        <div className="main-card-signin d-md-flex">
                            <div className="wd-100p">
                                <div className="d-flex mb-4">
                                    <a href="/">
                                        <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Logo"  className="sign-favicon" width="130px;" />
                                    </a>
                                </div>
                                <div className="">
                                    <div className="main-signup-header">
                                        <h2>Login</h2>
                                        <div className="panel panel-primary">
                                            <form className="mb3 mt-5">
                                                <a href="https://my.signinonce.com/login?application=utit_react" className="signInBtn custom-background-color" >
                                                    Sign in using Sign In Once
                                                    <img src={process.env.PUBLIC_URL + 'img/sio/logo-sio-icon.png'} alt="logo" />
                                                </a>
                                            </form>
                                        </div>
                                    </div>
                                    {/* <div className="main-signup-footer mt-3 text-center">
                                        <h5><a href="/" className='custom-footer-colo'>Create Account</a></h5>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </AuthLayout>
)

}

export default Login;
