import { useState,useMemo } from "react";
import Axios from "axios";
import AuthLayout from "../components/Layout/AuthLayout";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import moment from 'moment';

import { validEmail, validPassword } from "../components/Regex";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function RegistrationPage() {
  const options = useMemo(() => countryList().getData(), [])
  const [defaultSelect, setDefaultSelect] = useState();
  const search = useLocation().search;
  var emaildata = new URLSearchParams(search).get("emaildata");
  var sponsor_id = new URLSearchParams(search).get("sponsor_id");

  const [email, setEmail] = useState(emaildata);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("");
  const [cnfpassword, setCnfPassword] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [pwdErr, setPwdErr] = useState(false);
  const [pwdErrMessage, setPwdErrMessage] = useState("");
  const [cnfpwdErr, setCnfPwdErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [isRedirect, setIsRedirect] = useState(false);

  const [message, setMessage] = useState();
  const [isMessage, setIsMessage] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const [currentResponse, setCurrentResponse] = useState();

  const nameArr = name.split(" ");

  const navigate = useNavigate();



  const requestOptions = {
    name: name,
    email: email,
    password: password,
    source: "utit_react",
    first_name: nameArr[0],
    last_name: nameArr[1],
    country_id: country,
    sponsor_id: sponsor_id,
  };
  const headers = {
    "Content-Type": "application/json; charset=UTF-8",
    "base-token": "ab40db25fc6bde0eb707f68b8184d57c",
    // "user-token":"vlSe8OFU19zjFK3hx5nOZBUN92ESQhJPeZ6NuZzDHnyCIvVm9vR2ogOeF6LK",
  };

  function validateForm() {
    setNameErr(false);
    setEmailErr(false);
    setPwdErr(false);
    setCnfPwdErr(false);
    setCountryErr(false);
    setIsMessage(false);
    setMessage("");
    setPwdErrMessage("");
    var upperCaseRegex = new RegExp("(?=.*[A-Z])");
    var specialCharRegex = new RegExp("(?=.[!@#$%^&])");

    if (name.length == 0) {
      setNameErr(true);
      return;
    }

    if (email.length == 0) {
      setEmailErr(true);
      return;
    }

    if (password.length == 0) {
      setPwdErr(true);
      setPwdErrMessage("Password is required.");
      return;
    }

    if (password.length < 8) {
      setPwdErr(true);
      setPwdErrMessage("Password must be at least 8 characters.");
      return;
    }

    if (!upperCaseRegex.test(password)) {
      setPwdErr(true);
      setPwdErrMessage("At least one uppercase character is required.");
      return;
    }

    if (!specialCharRegex.test(password)) {
      setPwdErr(true);
      setPwdErrMessage("At least one special character is required.");
      return;
    }

    if (cnfpassword.length == 0) {
      setCnfPwdErr(true);
      return;
    }

    if (password !== cnfpassword) {
      setCnfPwdErr(true);
      return;
    }

    if (country.length == 0) {
      setCountryErr(true);
      return;
    }

    // submit form
    try {
      setNameErr(false);
      setEmailErr(false);
      setPwdErr(false);
      setCnfPwdErr(false);
      setCountryErr(false);
      setIsMessage(false);
      setMessage("");
      setPwdErrMessage("");

      setIsDisable(true);
      Axios.post("https://utradeitrade.puxeo.com/api/create-user", requestOptions, {
        headers,
      })
        .then((response) => {
          let resData = response.data;
          if (resData.status === 1) {
            setCurrentResponse(resData.data);
            toast("Register successful to A new confirmation e-mail has been sent to your email address.");
            setIsRedirect(true);
            // setMessage("User created successfully");
          } else {
            setMessage(resData.message);
            console.error(resData.message);
            setIsDisable(false);
            setIsMessage(true);
            setCountryErr(true);
          }
          setIsDisable(false);
          setIsMessage(true);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.data.result == false) {
            setMessage(error.response.data.error.message);
          }
          setIsDisable(false);
          setIsMessage(true);
          setCountryErr(true);
        });
    } catch (err) {
      console.log(err);
    }
  }

  if (isRedirect) {
    return <Navigate replace to={"/"} />;
  }

  // options.forEach((element, i) => {
  //   if (element.label == country) {
  //     console.log("i : " + i);
  //     setDefaultSelect(i);
  //     setCountry = options[i].value;
  //   }
  // });

  console.log("country : " + country);
  console.log(" default select : " + defaultSelect);

  const filterChangeHandler = (selectedYear) => {
    console.log(selectedYear);
    console.log(selectedYear.value);
    setCountry(selectedYear.value);
  };

  return (
    <AuthLayout>
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main py-45 justify-content-center mx-auto">
              <div className="card-sigin mt-5 mt-md-0">
                {isMessage ? (
                  <p className="alert alert-danger">{message}</p>
                ) : (
                  ""
                )}
                <div className="main-card-signin d-md-flex">
                  <div className="wd-100p">
                    <div className="d-flex mb-4">
                      <a href="/">
                        <img
                          src={process.env.PUBLIC_URL + "images/logo.png"}
                          alt="Logo"
                          width="130px;"
                        />
                      </a>
                    </div>
                    <div className="">
                      <div className="main-signup-header">
                        <h2 className="text-dark">Register</h2>

                        <form method="POST" id="signup_form">
                          <div>
                            <div className="form-group">
                              <input
                                label="name"
                                name="name"
                                type="text"
                                id="name"
                                className="form-control"
                                placeholder="Name"
                                onChange={(e) => setName(e.target.value)}
                                required
                              />
                              <label className="font-15">
                                (No Special Characters)
                              </label>
                              {nameErr && (
                                <p className="text-danger">
                                  Your name is invalid
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                autoComplete="off"
                                value={emaildata}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                              {emailErr && (
                                <p className="text-danger">
                                  Your email is invalid
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <input
                                style={{ marginBottom: 0 }}
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                              <label className="font-15">
                                (Min - 8 Characters, 1 Capital, 1 Special
                                Character)
                              </label>
                              {pwdErr && (
                                <p className="text-danger">{pwdErrMessage}</p>
                              )}
                            </div>
                            <div className="form-group">
                              <input
                                style={{ marginBottom: 0 }}
                                type="password"
                                name="password_confirmation"
                                className="form-control"
                                placeholder="Password Confirmation"
                                onChange={(e) => setCnfPassword(e.target.value)}
                                required
                              />
                              <label className="font-15">
                                (Re-Enter Password)
                              </label>
                              {cnfpwdErr && (
                                <p className="text-danger">
                                  The password confirmation does not match.
                                </p>
                              )}
                            </div>
                            <div className="form-group">
                              <Select
                                options={options}
                                name="country_id"
                                placeholder='Select Country'
                                defaultValue={options[defaultSelect]}
                                onChange={filterChangeHandler}
                              />

                              {countryErr && (
                                <p className="text-danger">
                                  Please select country
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-13 text-right">
                            {isDisable ? (
                              <button
                                type="submit"
                                id="verify"
                                className="btn btn-primary btn-block btn-flat "
                                disabled>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"></span>
                                Loding...
                              </button>
                            ) : (
                              <button
                                type="button"
                                id="register"
                                onClick={() => {
                                  validateForm();
                                }}
                                className="btn btn-primary btn-block btn-flat">
                                Register
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default RegistrationPage;
