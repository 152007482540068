import Layout from "../components/Layout/Layout";
import { useEffect, useState } from "react";
import axios from "axios";
import axiosBaseURL from "../components/Auth/httpCommon";
import Paginate from "../components/Paginate";
import AffiliateTab from "../components/AffiliateTab";
import PayoutTab from "../components/PayoutTab";
import Loader from '../components/Loader';

const WalletPage = () => {
  const [all, setAll] = useState(true);
  const [affilaite, setAffilate] = useState(false);
  const [payout, setPayout] = useState(false);
  const [walletData, setWalletData] = useState([]);
  const [value, setValue] = useState("");
  const [balance, setBalance] = useState(0);
  const [totalPayout, setTotalPayout] = useState(0);
  const [totalAffiliate, setTotalAffiliate] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const options = { year: "numeric", month: "long", day: "numeric" };

  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(1);
  // No of Records to be displayed on each page   
  const [recordsPerPage] = useState(5);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    
  const affiliatesTab = () => {
    setAffilate(true);
    setAll(false);
    setPayout(false);
  }
  const allTab = () => {
    setAffilate(false);
    setAll(true);
    setPayout(false);
  }
  const payoutTab = () => {
    setAffilate(false);
    setAll(false);
    setPayout(true);
  }
  
  var token2 = new URLSearchParams().get('token');
  if(token2 === null){
    token2 = JSON.parse(localStorage.getItem('token') || '') || null;
  }
  let email = JSON.parse(localStorage.getItem('email') || '') || null;


  useEffect(() => {
    axiosBaseURL.get("https://utradeitrade.puxeo.com/api/get-wallet-details?email="+email).then(response => {
        let resData = response.data;
        if(resData.status === 1){

          if(resData.data[0]){
            setBalance(resData.data[0].balance);
            setTotalPayout(resData.data[0].total_payout);
            setTotalAffiliate(resData.data[0].total_affiliate_commission);
            setWalletData(resData.data[0].transactions);
            console.info(resData.data[0].transactions);
            console.info("wallet data : "+walletData);
          }
          setLoading(false);

        }else{
          console.error(resData.message);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  if (isLoading) {
    return <Loader/>;
  }
  if (!walletData) {
    // return <Loader/>;
  }

  
  const nPages = Math.ceil(walletData.length / recordsPerPage)
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
  

  const nextPage = () => {
    if(currentPage !== nPages) 
        setCurrentPage(currentPage + 1)
  }
  const prevPage = () => {
    if(currentPage !== 1) 
        setCurrentPage(currentPage - 1)
  }
  

  console.info(walletData);

  return (
    <Layout>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h2>€ {balance}</h2>
                    <h6>Current Balance</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h2>€ {totalAffiliate}</h2>
                    <h6>Affiliate Commission</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h2>€ {totalPayout}</h2>
                    <h6>Total Payouts</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="panel panel-primary tabs-style-2">
                  <div className=" tab-menu-heading">
                    <div className="tabs-menu1">
                      <ul className="nav panel-tabs main-nav-line">
                        <li className="nav-item">
                          <a
                            className={ all ? "nav-link active" : "nav-link" }
                            data-toggle="tab"
                            href="#all_trans_tab"
                            onClick={allTab}
                            role="tab">
                            <span className="hidden-sm-up">
                              <i className="ti-home"></i>
                            </span>{" "}
                            <span className="hidden-xs-down">All</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={ affilaite ? "nav-link active" : "nav-link" }
                            data-toggle="tab"
                            href="#affiliates_tab"
                            onClick={affiliatesTab}
                            role="tab">
                            <span className="hidden-sm-up">
                              <i className="ti-home"></i>
                            </span>{" "}
                            <span className="hidden-xs-down">
                              Affiliate Earnings
                            </span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={ payout ? "nav-link active" : "nav-link" }
                            data-toggle="tab"
                            href="#payouts_tab"
                            onClick={payoutTab}
                            role="tab">
                            <span className="hidden-sm-up">
                              <i className="ti-email"></i>
                            </span>{" "}
                            <span className="hidden-xs-down">Payouts</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="tab-content tabcontent-border">
                  <div
                    className={ all ? "tab-pane active" : "tab-pane" }
                    id="all_trans_tab"
                    role="tabpanel">
                    <div className="p-20">
                      <div className="">
                        <div
                          id="all-datatable_wrapper"
                          className="dataTables_wrapper no-footer">
                          <table
                            className="table table-hover dataTable  table-bordered text-nowrap border-bottom dataTable no-footer dtr-inline"
                            id="all-datatable"
                            role="grid"
                            aria-describedby="all-datatable_info"
                            style={{ width: "947px" }}>
                            <thead>
                              <tr role="row">
                                <th tabIndex="0" rowSpan="1" colSpan="1" style={{ width: "365.112px", fontWeight:"bold" }}>
                                  Transaction Comment
                                </th>
                                <th tabIndex="0" rowSpan="1" colSpan="1" style={{ width: "317.05px",fontWeight:"bold" }}>
                                  Transaction Status
                                </th>
                                <th tabIndex="0" rowSpan="1" colSpan="1" style={{ width: "164.4px",fontWeight:"bold" }}>
                                  Amount
                                </th>
                                <th tabIndex="0" rowSpan="1" colSpan="1" style={{ width: "118.238px",fontWeight:"bold" }}>
                                  Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {walletData  && walletData.map((wall, i) => {
                              return <tr className="odd">
                                <td>{wall.transaction_comment}</td>
                                <td>{wall.transaction_status}</td>
                                <td>€ {wall.amount}</td>
                                <td>{new Date(wall.created_at).toLocaleDateString(undefined, options)}</td>
                              </tr>
                              }) }
                            {/* } */}
                            </tbody>
                          </table>
                          <Paginate
                              pageNumbers = {pageNumbers}
                              nPages = { nPages }
                              currentPage = { currentPage } 
                              setCurrentPage = { setCurrentPage }
                              nextPage = { nextPage }
                              prevPage = { prevPage }
                            />
                        </div>
                      </div>
                    </div>
                  </div>

                    <div className={affilaite ? "tab-pane active" : "tab-pane"}
                    id="affiliates_tab"
                    role="tabpanel">
                      <AffiliateTab data={walletData} />
                    </div>
                  <div
                    className={ payout ? "tab-pane p-20 active" : "tab-pane p-20" }
                    id="payouts_tab"
                    role="tabpanel">
                      <PayoutTab data={walletData} />
                  </div>
                  <div
                    className="tab-pane p-20"
                    id="order_payments_tab"
                    role="tabpanel">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className=" table table-bordered table-striped table-hover datatable datatable-order_transaction">
                              <thead>
                                <tr>
                                  <th style={{fontWeight:"bold"}}>Transaction Comment3</th>
                                  <th style={{fontWeight:"bold"}}>Transaction Status</th>
                                  <th style={{fontWeight:"bold"}}>Amount</th>
                                  <th style={{fontWeight:"bold"}}>Date</th>
                                </tr>
                              </thead>
                              <tbody></tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WalletPage;
