import { useState, useEffect } from "react";
import Axios from 'axios';
import AuthLayout from "../components/Layout/AuthLayout";
import {  useNavigate,Navigate  } from "react-router-dom";

function VerifyEmailPage() {
  const [email, setEmail] = useState();
  const [currentResponse, setCurrentResponse] = useState();
  const [message, setMessage] = useState();
  const [isMessage, setIsMessage] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  const url = window.location.href;
  const sponsor_id = url.split("/").pop();
  
  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add("bg-primary");
  });

  const currentParam = window.location.pathname.split("/").pop();
  const emailId = email;
  const requestOptions = { email: email, application : "utit_react" };
  const headers = {
    "Content-Type": "application/json; charset=UTF-8",
    "base-token": "ab40db25fc6bde0eb707f68b8184d57c",
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
        setIsDisable(true);
      Axios.post("https://my.signinonce.com/api/verifyEmail", requestOptions,{headers})
        .then((response) => {
          let resData = response.data;
          if (resData.status === 1) {
            setCurrentResponse(resData.data);
            console.log(emailId);
            setIsRedirect(true);
            setMessage("User Verified successfully");
          } else {
            setMessage(resData.message);
            console.error(resData.message);
          }
          setIsDisable(false)
          setIsMessage(true)
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  if (isRedirect) {
    return <Navigate replace to={'/register?emaildata='+email+'&sponsor_id='+sponsor_id} />;
  }

  return (
    <AuthLayout>
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-8 col-xs-10 card-sigin-main py-45 justify-content-center mx-auto">
              <div className="card-sigin mt-5 mt-md-0">
                {isMessage ?  
                <p className="alert alert-danger">
                   {message}
                </p>
                : '' }
                <div className="main-card-signin d-md-flex">
                
                  <div className="wd-100p">
                    <div className="d-flex mb-4">
                      <a href="/">
                        <img
                          src={process.env.PUBLIC_URL + "images/logo.png"}
                          alt="Logo"
                          width="130px;"
                        />
                      </a>
                    </div>
                    <div className="">
                      <div className="main-signup-header">
                        <h2 className="text-dark">Verify Email</h2>
                        <form method="POST" onSubmit={handleSubmit} >
                          <div>
                            <div className="form-group">
                              <label>Enter Your Email</label>
                              <input
                                type="email"
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <input type="hidden" name="id" value={currentParam} />
                          <div className="col-13 text-right">
                          {isDisable ?
                            <button
                              type="submit"
                              id="verify"
                              className="btn btn-primary btn-block btn-flat " disabled >
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Verifying...
                            </button>
                            :
                            <button
                            type="submit"
                            id="verify"
                            className="btn btn-primary btn-block btn-flat " >
                            Verify
                          </button>
                        }
                          </div>
                          
                          
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default VerifyEmailPage;
