import Layout from "../components/Layout/Layout";
import axios from "axios";
import { async } from "q";
import { useEffect, useState } from "react";
import { Routes, Route, Link,useLocation } from "react-router-dom";

import { useTable } from "react-table";
import Paginate from "../components/Paginate";
import copy from "copy-to-clipboard";
import Loader from '../components/Loader';

const LicensePage = () => {
  const widthAuto = {
    width: "auto",
  };

  // set data
  const [licenses, setLicense] = useState();
  const [value, setValue] = useState("");
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'id' })
  const [isLoading, setLoading] = useState(true);
  const [isCopied, setIsCopied] = useState({ 0: false, 1: true });
  const options = { year: "numeric", month: "long", day: "numeric" };

  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(1);
  // No of Records to be displayed on each page   
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  var token2 = new URLSearchParams(search).get('token');
  if(token2 === null){
    token2 = JSON.parse(localStorage.getItem('token') || '') || null;
  }

  let email = JSON.parse(localStorage.getItem('email') || '') || null;


  const copyToClipboard = (e) => {
    // console.info(e.currentTarget.id);

    let myId = e.currentTarget.id;

    copy(e.currentTarget.textContent);
    setIsCopied((prev) => ({ ...prev, [myId]: !prev[myId] }));

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const requestOptions = { email: email };
  const headers = {
    "Content-Type": "application/json; charset=UTF-8",
    "base-token": "ab40db25fc6bde0eb707f68b8184d57c",
    "user-token":token2,
  };

  useEffect(() => {
    // get data
    axios.post("https://utradeitrade.puxeo.com/api/fetch-licenses", requestOptions, {
        headers,
      }).then((response) => {
        let resData = response.data;
        if (resData.status === 1) {
          setLicense(resData.data);
          setLoading(false);
        } else {
          console.error(resData.message);
        }
      }).catch((error) => {
        console.error(error);
      });
  }, []);

  if (isLoading) {
    return <Loader/>;
  }

  const handleSearch = async (e) => {
    e.preventDefault();

  }

  const search_parameters = ["order_id", "name", "status"]

 function search(licenses) {
    return licenses.filter(
      (item) =>
        search_parameters.some((parameter) =>
          item[parameter].toString().toLowerCase().includes(value)
        )
    );
  }

  const nPages = Math.ceil(licenses.licenses.length / recordsPerPage)
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

  const nextPage = () => {
    if(currentPage !== nPages) 
        setCurrentPage(currentPage + 1)
  }
  const prevPage = () => {
    if(currentPage !== 1) 
        setCurrentPage(currentPage - 1)
  }
  
  return (
    <Layout>
      <div className="row row-sm">
        <div className="col-lg-12">
          <div className="card custom-card">
            <div className="card-body">
              <div className="table-responsive ">
                <div className="form-group pull-right">
                  <form onSubmit={handleSearch}>
                    <input
                      type="text"
                      className="search form-control"
                      placeholder="What you looking for?"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}/>
                    </form>
                </div>
                <table
                  className="table table-hover table-bordered text-nowrap border-bottom"
                  id="responsive-datatable">
                  <thead>
                    <tr>
                      <th className="wd-10p">Order ID</th>
                      <th className="wd-25p">Product Name</th>
                      <th className="wd-35p">License Key</th>
                      <th className="wd-15p">Expiry Date</th>
                      <th className="wd-15p">Availability</th>
                    </tr>
                  </thead>
                  <tbody>
                    {licenses.licenses && search(licenses.licenses).slice(indexOfFirstRecord, 
                                    indexOfLastRecord).map((license, i) => {
                        return (
                          <tr key={i}>
                            <td>
                            <Link to={"/order/"+license.order_id} >
                                {license.order_id}
                              </Link>
                              {/* <a href={"/order/"+license.order_id} >{license.order_id}</a> */}
                            </td>
                            <td>{license.name}</td>
                            <td>
                              <div className="form-group col-lg-12">
                                <p
                                  className="form-control cpn referral-link custom-license-font-size"
                                  id={license.key}
                                  aria-controls="collapse"
                                  key={i}
                                  onClick={copyToClipboard}>
                                  {license.key}
                                </p>
                                <p className="custom-color-green " >{isCopied[license.key] ? 'Copied!' : ''}</p>
                              </div>
                            </td>
                            <td>{new Date(license.expiry_date).toLocaleDateString(undefined, options)}</td>
                            <td>{license.status ? "Available" : "Used"}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                <Paginate
                    pageNumbers = {pageNumbers}
                    nPages = { nPages }
                    currentPage = { currentPage } 
                    setCurrentPage = { setCurrentPage }
                    nextPage = { nextPage }
                    prevPage = { prevPage }
                />
                {/* <nav aria-label="Page navigation ">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a href="#" className="page-link" onClick={prevPage}>Prev</a>
                                </li>
                                {
                                    pageNumbers.map(pgNumer => (
                                        <li key={pgNumer} className={`page-item ${currentPage === pgNumer ? 'active' : '' }`} >
                                            <a href="#" className="page-link" onClick={() => setCurrentPage(pgNumer)}>{pgNumer}</a>
                                        </li>
                                    ))
                                }
                                <li className="page-item">
                                    <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                </li>
                            </ul>
                        </nav> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};


// function prePage(){

//     if(currentPage !== 1){
//         setCurrentPage(currentPage - 1);
//     }
// }

// function changeCPage(id){
//     setCurrentPage(id);

// }

// function nextPage(){
//     if(currentPage !== nPage){
//         setCurrentPage(currentPage - 1);
//     }
// }

// function sortRows(rows, sort) {
//   return rows.sort((a, b) => {
//     const { order, orderBy } = sort

//     if (isNil(a[orderBy])) return 1
//     if (isNil(b[orderBy])) return -1

//     const aLocale = convertType(a[orderBy])
//     const bLocale = convertType(b[orderBy])

//     if (order === 'asc') {
//       return aLocale.localeCompare(bLocale, 'en', { numeric: isNumber(b[orderBy]) })
//     } else {
//       return bLocale.localeCompare(aLocale, 'en', { numeric: isNumber(a[orderBy]) })
//     }
//   })
// }

export default LicensePage;
