import Layout from "../components/Layout/Layout";

const DocumentPage = () => {
  return (
    <Layout>
      <div className="row row-sm">
        <div className="col-lg-12">
          <div className="card custom-card">
            <div className="card-body">
              {/* @if(request()->get('status'))
              <div
                className="alert alert-solid-info alert-dismissible fade show"
                role="alert">
                Your document is updated with requested details
                <button
                  aria-label="Close"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  type="button">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              @endif  */}
              {/* @if(!$userDocuments->isEmpty()) */}
              <div className="table-responsive">
                <table
                  className="table table-hover table-bordered text-nowrap border-bottom"
                  id="responsive-datatable">
                  <thead>
                    <tr>
                      <th className="wd-5p">ID</th>
                      <th className="wd-25p">Name</th>
                      <th className="wd-15p">Type</th>
                      <th className="wd-15p">Status</th>
                      <th className="wd-20p">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* @foreach ($userDocuments as $userDocument) */}
                    <tr>
                      <td>document id</td>
                      <td>name</td>
                      <td>type</td>
                      <td>status</td>
                      <td>
                        <input
                          type="hidden"
                          className="clsDoc"
                          value="{{$userDocument->id}}"
                        />
                        <input
                          type="hidden"
                          className="name"
                          value="{{$userDocument->name}}"
                        />
                        <input
                          type="hidden"
                          className="account_number"
                          value="{{$userDocument->account_number}}"
                        />
                        <a
                          className="btn btn-primary btn-sm btnProcess"
                          href=""
                          data-bs-target="#exampleModal"
                          data-bs-toggle="modal">
                          <i className="fas fa-sync-alt"></i>
                          Process
                        </a>
                        <a className="btn btn-primary btn-sm" href="#">
                          <i className="fa fa-envelope"></i>
                        </a>
                        <a
                          className="btn btn-primary btn-sm"
                          href="{{$userDocument->path}}"
                          target="_blank">
                          <i className="fas fa-download"></i>
                        </a>
                      </td>
                    </tr>
                    {/* @endforeach */}
                  </tbody>
                </table>
              </div>
              {/* @else
              <div className="alert alert-default">No Documents Found.</div>
              @endif */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DocumentPage;
