import { useEffect, useState } from "react";
import Paginate from "./Paginate";

const PayoutTab = (props) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    // User is currently on this page
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(5);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    let payout = [];
    props.data.map((wallet,i) => {
        console.info(wallet);
        if(wallet.wallet_meta_type == 'Payout Commission'){
            payout[i] = wallet;
        }
    })
    
  const nPages = Math.ceil(payout.length / recordsPerPage)
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
  // }

  const nextPage = () => {
    if(currentPage !== nPages) 
        setCurrentPage(currentPage + 1)
  }
  const prevPage = () => {
    if(currentPage !== 1) 
        setCurrentPage(currentPage - 1)
  }
  return (
    <div className="">
      <table
        className="table table-hover table-bordered text-nowrap border-bottom dataTable no-footer dtr-inline"
        id="responsive-datatable"
        role="grid"
        aria-describedby="responsive-datatable_info"
        style={{ width: "947px" }}>
        <thead>
          <tr>
            <th style={{fontWeight:"bold"}}>Transaction Comment</th>
            <th style={{fontWeight:"bold"}}>Transaction Status</th>
            <th style={{fontWeight:"bold"}}>Amount</th>
            <th style={{fontWeight:"bold"}}>Date</th>
          </tr>
        </thead>
        <tbody>
            { payout.length <= 0 && 
                <tr className="odd"><td valign="top" colSpan="4" className="dataTables_empty">No data available in table</td></tr>
            }
        { payout.map((wall) => {
                return <tr className="odd">
                  <td>{wall.transaction_comment}</td>
                  <td>{wall.transaction_status}</td>
                  <td>{wall.amount}</td>
                  <td>{new Date(wall.created_at).toLocaleDateString(undefined, options)}</td>
                </tr>
                }) }
        </tbody>
        { payout.length > 0 && 
        <Paginate pageNumbers = {pageNumbers}
                    nPages = { nPages }
                    currentPage = { currentPage } 
                    setCurrentPage = { setCurrentPage }
                    nextPage = { nextPage }
                    prevPage = { prevPage }
                />
        }
      </table>
    </div>
  );
};

export default PayoutTab