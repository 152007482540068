import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'

function CountrySelector(props) {

  const [value, setValue] = useState('')
  const options = useMemo(() => countryList().getData(), [])

  const changeHandler = value => {
    setValue(value)
  }

  let defaultSelect = '';
  options.forEach((element,i) => {
      if(element.label == props.data){
        defaultSelect = i;
        // setValue(options[defaultSelect]);
      }
  });

  

  console.info(options[defaultSelect]);

  return <Select options={options} name="country_id" defaultValue={options[defaultSelect]}   />
}

export default CountrySelector