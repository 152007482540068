import Layout from "../components/Layout/Layout";
import { Routes, Route, Link,useLocation  } from "react-router-dom";
import useToken from "../components/Auth/UseToken"
import useEmail from "../components/Auth/UseEmail"
import axiosBaseURL from "../components/Auth/httpCommon";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from '../components/Loader';

function HomePage() {
  const [dashboard, setDashboard] = useState();
  const [isLoading, setLoading] = useState(true);
  const {token, setToken} = useToken()
  const {email, setEmail} = useEmail()

  const search = useLocation().search;
  var email2 = new URLSearchParams(search).get('email');
  if(email2 === null){
    email2 = JSON.parse(localStorage.getItem('email') || '') || null;
  }
  var token2 = new URLSearchParams(search).get('token');
  if(token2 === null){
    token2 = JSON.parse(localStorage.getItem('token') || '') || null;
  }

    const requestOptions = {email: email2};

    const headers = {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin":"*",
        "base-token": "ab40db25fc6bde0eb707f68b8184d57c",
        "user-token": token2
    };

  useEffect(() => {

    if(token2 !== null){
      setToken(token2)
    }
    if(email2 !== null){
      setEmail(email2)
    }

    axios.post('https://utradeitrade.puxeo.com/api/dashboard',requestOptions,{ headers })
      .then(response => {
        let resData = response.data;
        if(resData.status === 1){
          setDashboard(resData.data);
          setLoading(false);
        }else{
          console.error(resData.message);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  if (isLoading) {
    return <Loader/>;
  }


  return (
    <Layout>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
          <div className="row">
            <div className="col-xl-3 col-lg-12 col-md-12 col-xs-12">
              <div className="card sales-card">
                <div className="row">
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Broker</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">€ {dashboard.broker}</h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">Euro Trader</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon text-center align-self-center overflow-hidden">
                      <img
                        src={process.env.PUBLIC_URL + "img/exchanges.png"}
                        className=""
                        alt="Exchanges"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-12 col-md-12 col-xs-12">
              <div className="card sales-card">
                <div className="row">
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Exchanges</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            USDT {dashboard.exchanges}
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">Binance</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon text-center align-self-center overflow-hidden">
                      <img
                        src={process.env.PUBLIC_URL + "img/broker.png"}
                        alt="broker"
                        className=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-12 col-md-12 col-xs-12">
              <div className="card sales-card">
                <div className="row">
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-2">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Cashback</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className=" right_align">
                          <div className="badge badge-info">
                            <span>Total </span>
                            <span>{dashboard.cashback}</span>
                          </div>
                          <div className="badge badge-info" style={{marginLeft : "4px"}}>
                            <span className="">Available </span>
                            <span>N/A</span>
                          </div>
                        </div>
                        <a
                          href="/"
                          className="btn btn-warning btn-sm utit_header_link">
                          Visit CB UTIT
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-primary-transparent  text-center align-self-center overflow-hidden">
                      <i className="fe fe-shopping-bag tx-16 text-primary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-12 col-md-12 col-xs-12">
              <div className="card sales-card">
                <div className="row">
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Community</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-2">
                            &nbsp;
                          </h4>
                        </div>
                        <p className="mb-0 tx-12 text-muted">Comming Soon</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon text-center align-self-center overflow-hidden"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12 col-xs-12">
              <div className="card">
                <div className="card-header bg-transparent pb-0">
                  <div>
                    <h3 className="card-title mb-2">Most Popular Products</h3>
                  </div>
                </div>

                <div className="card-body pt-0">
                  <div className="row">
                  {dashboard.products.map((product) => {
                    let thumbnail = '';
                    if(product.photo){
                      thumbnail = product.photo.url;
                    }
                    return <div className="col-md-6 col-lg-6 col-xl-4  col-sm-6" key={product.id}>
                      <div className="card">
                        <div className="card-body h-100  product-grid6">

                        {thumbnail.length > 0 &&
                          <div className="card product_tag">
                            <span className="img_tag">{product.tag ? product.tag : ''}</span>
                          </div>
                          }
                          {thumbnail.length > 0 &&
                          <div className="pro-img-box product_image product-image text-center">
                            <a href="#">
                              <img
                                src={thumbnail}
                                className="product_image pic-1"
                                alt="Product Image"
                              />
                            </a>
                          </div>
                          }

                          <div className="text-center pt-2">
                            <a href="#">
                              <h3 className="group inner h6 mb-2 mt-4 font-weight-bold text-uppercase">
                                {product.name}
                              </h3>
                            </a>
                            <span className="category-list">
                              {product.short_description}
                            </span>
                            {/* @if(!is_null($product->vendor_id)) */}
                            <h6 className="price">
                              Provided By :
                              <a href="/">
                                {/* @php
                                                                    $vendor_name = App\Models\User::where('id',$product->vendor_id)->get()->first();
                                                                    echo $vendor_name->name;
                                                                @endphp */}
                              </a>
                            </h6>
                            {/* @endif */}

                            {/* <h4 className="h5 mb-0 mt-1 text-center font-weight-bold  tx-22"><strong className="price">EUR {product.price}</strong></h4><br/> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    })} 
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-lg-12 col-xl-4">
              <div className="card">
                <div className="card-header bg-transparent pb-0">
                  <div>
                    <h3 className="card-title mb-2">News and Updates</h3>
                  </div>
                </div>
                <div className="card-body mt-0">
                  <div className="latest-timeline mt-4">
                    <ul className="timeline mb-0">
                      {/* @if(!$alerts->isEmpty())
                                        @foreach($alerts as $alert) */}
                      <li>
                        <div className="featured_icon1 primary"></div>
                      </li>
                      <li className="mt-0 activity">
                        <div>
                          <span className="tx-11 text-muted float-end">
                            Date
                          </span>
                        </div>
                        <a
                          href="{{ $alert->alert_link }}"
                          target="_blank"
                          className="tx-12 text-dark">
                          <p className="mb-1 font-weight-semibold text-dark">
                            Alert text
                          </p>
                        </a>
                      </li>
                      {/* @endforeach
                                    @else */}
                      <span>No News Found.</span>
                      {/* @endif */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default HomePage;
