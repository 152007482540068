import Axios from 'axios';

let token2 = localStorage.getItem('token') || '';
let token = '';
if(token2){
    token = JSON.parse(token2) || null;
}

const axiosBaseURL = Axios.create({
    baseURL:'https://utradeitrade.puxeo.com/'
});

if(token === ''){
    console.log("Token is empty");
    
}else{
    //console.log(`Token
}

// Alter defaults after instance has been created
axiosBaseURL.defaults.headers.common['Content-Type'] = "application/json; charset=UTF-8";
// axiosBaseURL.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
// axiosBaseURL.defaults.headers.common['origin'] = "*";
axiosBaseURL.defaults.headers.common['base-token'] = "ab40db25fc6bde0eb707f68b8184d57c";
axiosBaseURL.defaults.headers.common['user-token'] = token;

export default axiosBaseURL