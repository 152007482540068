const Footer = () => {
  var today = new Date()

    return (
      <div className="main-footer">
      <div className="container-fluid pd-t-0-f ht-100p container">
                  <p className="m-0 tx-13">
                      Copyright &copy; { today.getFullYear() } <a href="/">UtradeItrade</a>. All rights reserved.
                  </p>
              </div>
    </div>
    )
  }
  
  export default Footer