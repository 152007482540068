import Layout from "../components/Layout/Layout";
import axios from "axios";
import { useEffect, useState } from "react";
import { Routes, Route, Link,useLocation } from "react-router-dom";
import axiosBaseURL from "../components/Auth/httpCommon";
import Paginate from "../components/Paginate";
import Loader from '../components/Loader';

const OrderPage = () => {

  const widthAuto = {
    width: "auto",
  }
  // const search = useLocation().search;


  var token2 = new URLSearchParams(search).get('token');
  if(token2 === null){
    token2 = JSON.parse(localStorage.getItem('token') || '') || null;
  }

  let email = JSON.parse(localStorage.getItem('email') || '') || null;

  const [orderParam,setOrderParam] = useState();
  const [orders, setOrder] = useState();
  const [value, setValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const options = { year: "numeric", month: "long", day: "numeric" };

    // User is currently on this page
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(10);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  

    const requestOptions = {email: email};
    const headers = {
      "Content-Type": "application/json; charset=UTF-8",
      "base-token": "ab40db25fc6bde0eb707f68b8184d57c",
      "user-token":token2,
    };

  useEffect(() => {
    axios.post("https://utradeitrade.puxeo.com/api/fetch-order", requestOptions, {
      headers,
  }).then(response => {
        let resData = response.data;
        if(resData.status === 1){
          setOrderParam(resData);
          setOrder(resData.data);
          setLoading(false);

        }else{
          console.error(resData.message);
        }
        
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  
  if (isLoading) {
    return <Loader/>;
  }

  const handleSearch = async (e) => {
    e.preventDefault();

  }

  const search_parameters = ["order_id", "order", "payment_method","order_status"]

 function search(orders) {
    return orders.filter(
      (item) =>
        search_parameters.some((parameter) =>
          item[parameter].toString().toLowerCase().includes(value)
        )
    );
  }

  if(!orders){
    return <Loader/>;
  }

  // if(orders){
  // console.info(orders.length)
  
  const nPages = Math.ceil(orders.length / recordsPerPage)
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
  // }

  const nextPage = () => {
    if(currentPage !== nPages) 
        setCurrentPage(currentPage + 1)
  }
  const prevPage = () => {
    if(currentPage !== 1) 
        setCurrentPage(currentPage - 1)
  }

  return (
    <Layout>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
          <div className="row">
            <div className="col-xl-3 col-lg-12 col-md-12 col-xs-12">
              <div className="card sales-card">
                <div className="row">
                  <div className="col-8">
                    <div className="ps-4 pt-4 pe-3 pb-4">
                      <div className="">
                        <h6 className="mb-2 tx-12 ">Total Orders</h6>
                      </div>
                      <div className="pb-0 mt-0">
                        <div className="d-flex">
                          <h4 className="tx-20 font-weight-semibold mb-4">{orderParam.order_count}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="circle-icon bg-primary-transparent text-center align-self-center overflow-hidden">
                      <i className="fe fe-shopping-bag tx-16 text-primary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-12 col-md-12 col-xs-12">
              <div className="card sales-card">
                <div className="row">
                  <div className="col-12">
                    <div className="ps-4 pt-3 pe-3  ">
                      <div className="">
                        <h2 className="mb-0 mt-1 tx-12">Cashback Licenses</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 ps-4 pe-0 pb-2 pt-2">
                    <div className="text-center">
                      <h4 className="tx-20 font-weight-semibold mb-2">{orderParam.license_count}</h4>
                    </div>
                    <p className="mb-0 tx-12 text-muted text-center">
                      Total Licenses
                    </p>
                  </div>
                  <div className="col-6 ps-2 pe-3 pb-2 pt-2">
                    <div className="text-center">
                      <h4 className="tx-20 font-weight-semibold mb-2">{orderParam.available_licenses
}</h4>
                    </div>
                    <p className="mb-0 tx-12 text-muted text-center">
                      Available Licenses
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-xs-12">
              <div className="card sales-card">
                <div className="row">
                  <div className="col-12">
                    <div className="ps-4 pt-3 pe-3  ">
                      <div className="">
                        <h2 className="mb-0 mt-1 tx-12">Marketplace</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 ps-4 pe-3 pb-2 pt-2">
                    <div className="text-center">
                      <h4 className="tx-20 font-weight-semibold mb-2">{ Math.round(orderParam.total_bot) }</h4>
                    </div>
                    <p className="mb-0 tx-12 text-muted text-center">
                      Crypto bot licenses
                    </p>
                  </div>
                  <div className="col-3 ps-4 pe-3 pb-2 pt-2">
                    <div className="text-center">
                      <h4 className="tx-20 font-weight-semibold mb-2">{orderParam.platform}</h4>
                    </div>
                    <p className="mb-0 tx-12 text-muted text-center">
                      botTrader Platform
                    </p>
                  </div>
                  <div className="col-3 ps-4 pe-3 pb-2 pt-2">
                    <div className="text-center">
                      <h4 className="tx-20 font-weight-semibold mb-2">{orderParam.expert_advisors}</h4>
                    </div>
                    <p className="mb-0 tx-12 text-muted text-center">
                      Expert Advisors
                    </p>
                  </div>
                  <div className="col-3 ps-4 pe-3 pb-2 pt-2">
                    <div className="text-center">
                      <h4 className="tx-20 font-weight-semibold mb-2">{orderParam.MAM_follower_accounts}</h4>
                    </div>
                    <p className="mb-0 tx-12 text-muted text-center">
                      MAM follower accounts
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row row-sm">
        <div className="col-lg-12">
          <div className="card custom-card">
            <div className="card-body">
              <div className="table-responsive">
                <div
                  id="responsive-datatable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer" >
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      {/* <div
                        className="dataTables_length"
                        id="responsive-datatable_length" >
                        <label>Show
                          <select name="responsive-datatable_length" aria-controls="responsive-datatable" className="form-select form-select-sm select2 select2-hidden-accessible" tabIndex="-1" aria-hidden="true" > <option value="10">10</option> <option value="25">25</option> <option value="50">50</option> <option value="100">100</option>
                          </select>
                          <span className="select2 select2-container select2-container--default" dir="ltr" style={widthAuto} >
                            <span className="selection">
                              <span     className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-labelledby="select2-responsive-datatable_length-h1-container" >
                                <span className="select2-selection__rendered" id="select2-responsive-datatable_length-h1-container" title="10" >
                                  10
                                </span>
                                <span className="select2-selection__arrow" role="presentation" >
                                  <b role="presentation"></b>
                                </span>
                              </span>
                            </span>
                            <span   className="dropdown-wrapper"   aria-hidden="true"></span>
                          </span>
                          entries
                        </label>
                      </div> */}
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div id="responsive-datatable_filter" className="dataTables_filter" >
                      <form onSubmit={handleSearch}>
                        <label>
                          <input type="search" 
                            className="form-control form-control-sm " 
                            placeholder="Search..." 
                            aria-controls="responsive-datatable"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}   />
                        </label>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <table className="table table-hover table-bordered text-nowrap border-bottom dataTable no-footer dtr-inline" id="responsive-datatable" role="grid" aria-describedby="responsive-datatable_info" >
                        <thead>
                          <tr role="row">
                            <th className="wd-5p sorting_disabled"   rowSpan="1"   colSpan="1">Order ID
                            </th>
                            <th className="wd-25p sorting_disabled"   rowSpan="1"   colSpan="1"> Order Details
                            </th>
                            <th className="wd-15p sorting_disabled"   rowSpan="1"   colSpan="1"> Payment Method
                            </th>
                            <th className="wd-15p sorting_disabled"   rowSpan="1"   colSpan="1"> Status
                            </th>
                            <th className="wd-10p sorting_disabled"   rowSpan="1"   colSpan="1"> Date
                            </th>
                            <th className="wd-10p sorting_disabled"   rowSpan="1"   colSpan="1"> Total
                            </th>
                            <th className="wd-20p sorting_disabled"   rowSpan="1"   colSpan="1"> Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders  && search(orders).slice(indexOfFirstRecord, 
                                    indexOfLastRecord).map((order, i) => {
                          return <tr className="odd" key={i}>
                              <td className="dtr-control" tabIndex="0"> {order.order_id}
                              </td>
                              <td> Warrior Tradingbot <br />
                              </td>
                              <td>{order.payment_method} </td>
                              <td> <span className="badge badge-warning">{order.order_status}</span>
                              </td>
                              <td>{new Date(order.created_at).toLocaleDateString(undefined, options)}</td>
                              <td>€ { new Intl.NumberFormat('en-DE').format(Math.round(order.order_total,2))}</td>
                              <td> 
                              <Link to={"/order/"+order.order_id} className="btn btn-primary btn-sm">
                                <i className="fas fa-folder" style={{marginRight : "3px"}}></i>View
                              </Link>
                                <a className=""   href="/order/" >
                                  
                                </a>
                              </td>
                            </tr>
                          })}

                        </tbody>
                      </table>
                <Paginate
                    pageNumbers = {pageNumbers}
                    nPages = { nPages }
                    currentPage = { currentPage } 
                    setCurrentPage = { setCurrentPage }
                    nextPage = { nextPage }
                    prevPage = { prevPage }
                />
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default OrderPage;
