import Layout from "../components/Layout/Layout";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Loader from '../components/Loader';

const OrderDetailPage = () => {
  const location = useLocation();
  const pathnameParts = location.pathname.split("/").filter(Boolean);
  const options = { year: "numeric", month: "long", day: "numeric" };

  const width75 = {
    width: "75px",
  };

  const width70 = {
    width: "70px",
  };

  var token2 = new URLSearchParams().get("token");
  if (token2 === null) {
    token2 = JSON.parse(localStorage.getItem("token") || "") || null;
  }
  let email = JSON.parse(localStorage.getItem("email") || "") || null;

  const [order, setOrder] = useState();
  const [invoice, setInvoice] = useState();
  const [value, setValue] = useState("");
  const [isLoading, setLoading] = useState(false);

  const requestOptions = { email: email };
  const requestOptionsInvoice = { order_id: pathnameParts[1] };
  const headers = {
    "Content-Type": "application/json; charset=UTF-8",
    "base-token": "ab40db25fc6bde0eb707f68b8184d57c",
    "user-token": token2,
  };

  useEffect(() => {
    axios.post("https://utradeitrade.puxeo.com/api/fetch-order", requestOptions, {
      headers,
  }).then(response => {
        let resData = response.data;
        if(resData.status === 1){
          console.info(resData.data);
          const found = resData.data.find(obj => {
            if(pathnameParts[1] == obj.order_id){
              setOrder(obj);
              setLoading(false);
            }
          });
          
        }else{
          console.error(resData.message);
        }
      })
      .catch(error => {
        console.error(error);
      });


      // get invoice
      axios.post("https://utradeitrade.puxeo.com/api/fetch-invoice", requestOptionsInvoice, {
      headers,
  }).then(response => {
        let resData = response.data;
        if(resData.status === 1){
          console.info(resData.data);
          setInvoice(resData.data)
          
        }else{
          console.error(resData.message);
        }
      })
      .catch(error => {
        console.error(error);
      });

  }, []);

  if (isLoading && pathnameParts[1] != '') {
    return <Loader/>;
  } 

  if(!order){
    return <Loader/>;
  }

  console.info(new Intl.NumberFormat('en-DE').format(Math.round(order.product_detail[0].item_price,2)))
  

  return (<Layout>
      <section className="content">
        <div className="row">
          <div className="col-md-8">
            <div className="card card-solid">
              <div className="card-header">
                <h4 className="text-justified pt-2">Transaction Details</h4>
              </div>
              <div className="card-body">
                <p className="pull-left">Your transaction is <span className="badge badge-warning" style={{fontSize: "100%"}}>{order.order_status}</span></p>
                <p className="pull-right">
                  Order No : {order.order}
                  <br />
                  Date : {new Date(order.created_at).toLocaleDateString(undefined, options)}
                  <br />
                  Platform expire on: {order.product_detail.cycle_ends_at}
                </p>
                <div className="table-responsive">
                  <table className="table mg-b-0 text-md-nowrap">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Product Details</th>
                        <th>License</th>
                        <th className="text-right">Quantity</th>
                        <th className="text-right">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={width75}>
                          <img
                            src="https://utradeitrade.puxeo.com/storage/38/646e28d977ea8_wtb_trading_bot.png"
                            className="img-responsive"
                            alt="Trading Bot"
                            style={width70} />
                        </td>
                        <td>
                          <a className="h5" href="#">
                            {order.product_detail[0].product_name}
                          </a>
                        </td>
                        <td>
                          <div className="font-w600">
                            <h6>N/A</h6>
                          </div>
                        </td>
                        <td className="text-right">
                          <div className="font-w600 text-success">{Math.round(order.product_detail[0].item_qty)}</div>
                        </td>
                        <td className="text-right">
                          <div className="font-w600 text-success">€ { new Intl.NumberFormat('en-DE').format(order.product_detail[0].item_price)}</div>
                        </td>
                      </tr>
                      <tr className="success">
                        <td className="text-right" colSpan="4">
                          <span className="h4 font-w600">Sub Total</span>
                        </td>
                        <td className="text-right">
                          <div className="h4 font-w600 text-success">
                            € {new Intl.NumberFormat('en-DE').format(order.order_total)}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">Order Summary</div>
              <div className="card-body table-responsive">
                <table className="table mg-b-0 text-md-nowrap"
                  id="payment-detail-table">
                  <tbody>
                    <tr>
                      <td>Order Total</td>
                      <td className="text-right">€ {new Intl.NumberFormat('en-DE').format(order.order_total)}</td>
                    </tr>
                    {/* @if(isset($order->discount) && $order->discount > 0)
                    <tr>
                      <td>Discount</td>
                      <td className="text-right">€ 0</td>
                    </tr>
                    @endif */}
                    <tr>
                      <td>Vat@ {order.discount}</td>
                      <td className="text-right">€ {new Intl.NumberFormat('en-DE').format(order.discount)}</td>
                    </tr>
                    <tr className="success">
                      <td className="text-right" colSpan="1">
                        <span className="h4 font-w600">Total</span>
                      </td>
                      <td className="text-right">
                        <div className="h4 font-w600 text-success">€ {new Intl.NumberFormat('en-DE').format(order.net_total)}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="custom-text-align-center">
              <a className="btn btn-primary btn-block btn-flat" target="_blank" href={invoice.invoice}>
                <i className="fas fa-download" style={{marginLeft : "3px"}}></i>
                Download Invoice
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header">Payment Summary</div>
              <div className="card-body table-responsive">
                <table className="table mg-b-0 text-md-nowrap" id="payment-detail-table">
                  <thead>
                    <tr>
                      <th className="custom-order-border-bottom">
                        Payment Option
                      </th>
                      <th className="custom-order-border-bottom text-right">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{order.payment_method}</td>
                      <td className="text-right">
                        <div className="h4 font-w600 text-success">€ {new Intl.NumberFormat('en-DE').format(order.order_total)}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>);
};

export default OrderDetailPage;
