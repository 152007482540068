import { Link, useLocation } from "react-router-dom";

function Breadcrumbs() {
  const location = useLocation();
  const pathnameParts = location.pathname.split("/").filter(Boolean);
  let myParam = "";

  if (pathnameParts[0] == "home") {
    myParam = "dashboard";
  } else {
    myParam = pathnameParts[0];
  }
  return (
    <div className="justify-content-center mt-2">
      <ol className="breadcrumb">
        <li className="breadcrumb-item  tx-15">
          <a href="/home">Home</a>
        </li>

        {myParam === "order" ? (
          <li className="breadcrumb-item  tx-15">
            <a href="/orders" style={{    background: "#2D7DD2",padding: "5px 10px",color: "white",borderRadius: "5px"}}>Back to Orders</a>
          </li>
        ) : (
          <li
            className={
              location.pathname === "/"
                ? "breadcrumb-item"
                : "breadcrumb-item active"
            }
            aria-current="page">
            {myParam.charAt(0).toUpperCase() + myParam.slice(1)}
          </li>
        )}
      </ol>
    </div>
  );
}

export default Breadcrumbs;
