import React, { useState } from 'react';

export const Subscription = (props) => {

    const subscription = props.data;
    return (
        <div
                className="main-content-body  tab-pane border-top-0"
                id="subscription">
                <div className="card mb-2">
                  {/* @if(!empty($platform)) */}
                  { subscription && subscription.platform != null ?
                  <div className="card-body">
                    <div className="alert alert-success mb-2" role="alert">
                      <h4 className="alert-heading">
                        <i className="fe fe-check-circle me-2 tx-20"></i>Current
                        Trading platform license - <b>{subscription.bot} bots</b>
                      </h4>
                      <p>Expires on - {subscription.platform.cycle_end_at} </p>
                      <hr />
                      <p className="mb-0">
                        License key - {subscription.platform.licence_key}
                        { subscription.platform.cycle_end_at < Date('Y-m-d H:i:s') || (subscription.bot != 35) &&
                        <button
                          type="button"
                          className="btn btn-primary buttonMarginLeft"
                          data-toggle="modal"
                          data-target="#upgradeModal">
                          Upgrade
                        </button>
                        }
                      </p>
                    </div>
                  </div>
                  :
                  <div className="card-body">
                    <div className="alert alert-warning mb-2" role="alert">
                      <h4 className="alert-heading">
                        <i className="fe fe-alert-octagon me-2 tx-20"></i>You
                        didn't purchase a subscription
                      </h4>
                    </div>
                    <div className="text-center">
                      <a
                        className="btn btn-primary"
                        href="#">
                        Buy Now
                      </a>
                    </div>
                  </div>
                  }
                </div>
              </div>
    );
}

export default Subscription
