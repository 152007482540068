import Layout from "../components/Layout/Layout";
import { useEffect, useState } from "react";
import axios from "axios";
import '../assets/plugins/treeview/treeview.css'
import GeneologyTree from "../components/GeneologyTree";
import Loader from '../components/Loader';

const GeneologyPage = () => {
  const [geneology, setGeneology] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const expand = () => {
    setIsVisible(!isVisible);
  };

  var token2 = new URLSearchParams().get("token");
  if (token2 === null) {
    token2 = JSON.parse(localStorage.getItem("token") || "") || null;
  }

  let email = JSON.parse(localStorage.getItem("email") || "") || null;

  const requestOptions = { email: email };
  const headers = {
    "Content-Type": "application/json; charset=UTF-8",
    "base-token": "ab40db25fc6bde0eb707f68b8184d57c",
    "user-token": token2,
  };

  useEffect(() => {
    // get data
    axios
      .post("https://utradeitrade.puxeo.com/api/tree-view", requestOptions, {
        headers,
      })
      .then((response) => {
        let resData = response.data;
        if (resData.status === 1) {
            console.info(response.data);
          setGeneology(resData.data);
          setLoading(false);
        } else {
          console.error(resData.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (isLoading) {
    return <Loader/>;
  }

  return (
    <Layout>
      {" "}
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12">
              <ul id="treeview1" className="tree">
                { geneology.length > 0 && geneology.map((gen) =>
                 <li className="branch" onClick={expand} >
                  <i className={isVisible ? "si fe fe-folder-minus" : "si fe fe-folder-plus"}></i>
                  <b>{gen.name}</b> - {gen.email} ({gen.children.length})

                  { gen.children && gen.children.map((gene, i) => 
                  <>
                    { isVisible ? 
                      <ul><li props={i} style={{display: "block"}}>{gene.name} -{gene.email}</li></ul>
                      : '' }
                      </>
                  )}

                  {/* <b>{gen.name}</b> - &nbsp;{gen.email}&nbsp;({gen.children.length})
                    <GeneologyTree geneology={gen} /> */}
                    </li>
                )}
              </ul>
            </div>

            <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
              <div className="card overflow-hidden">
                <div className="card-header pb-1">
                  <h3 className="card-title mb-2">Summary</h3>
                </div>
                <div className="card-body p-0 customers mt-1">
                  <div className="list-group list-lg-group list-group-flush">
                    <a href="#" className="border-0">
                      <div className="list-group-item list-group-item-action border-0">
                        <div className="media mt-0">
                          <span className="me-3 bg-primary-transparent text-primary transaction-icon">
                            <i className="fe fe-chevrons-right"></i>
                          </span>
                          <div className="media-body">
                            <div className="d-flex align-items-center">
                              <div className="mt-0">
                                <h4 className="mb-1 tx-13 font-weight-sembold text-dark">
                                  <span className="me-3">
                                    <font style={{ verticalAlign: "inherit" }}>
                                      <font
                                        style={{ verticalAlign: "inherit" }}>
                                        First Level Clients
                                      </font>
                                    </font>
                                  </span>
                                </h4>
                                <p className="mb-0 tx-12 text-muted">
                                  First level
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>

                    <a href="#" className="border-0">
                      <div className="list-group-item list-group-item-action border-0">
                        <div className="media mt-0">
                          <span className="me-3 bg-info-transparent text-info transaction-icon">
                            <i className="fe fe-more-horizontal"></i>
                          </span>
                          <div className="media-body">
                            <div className="d-flex align-items-center">
                              <div className="mt-1">
                                <h4 className="mb-1 tx-13 font-weight-sembold text-dark">
                                  <span className="me-3">
                                    Second Level Clients
                                  </span>
                                </h4>
                                <p className="mb-0 tx-12 text-muted">
                                  second level
                                </p>
                              </div>
                              <span className="ms-auto wd-45p  tx-12">
                                {" "}
                                <span className="text-primary tx-11 text-end d-block"></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GeneologyPage;
