import Navigation from "./Navigation";
import Footer from "./Footer";
import { Sidebar } from "./Sidebar";
import "../../App.css";
import "../../assets/css/style.css";
import Breadcrumbs from "../Breadcrumbs";
import { Link, useLocation } from "react-router-dom";
import React from "react";

const Layout = ({ children }) => {

  const location = useLocation();
  const pathnameParts = location.pathname.split("/").filter(Boolean);
  let myParam = '';

  if(pathnameParts[0] == 'home'){
    myParam = 'dashboard';
  }else if(pathnameParts[0] == 'orders'){
    myParam = 'order list';
  }else if(pathnameParts[0] == 'order'){
    myParam = 'order detail';
  }else if(pathnameParts[0] == 'licenses'){
    myParam = 'license list';
  }else if(pathnameParts[0] == 'wallet'){
    myParam = 'my wallet';
  }else{
    myParam = pathnameParts[0];
  }

  return (
    <>
      <Navigation />
      <div>
      <Sidebar />
      </div>
      <div className="main-content ">
        <div className="main-container container-fluid">
          <div className="col-lg-12">
            <div className="breadcrumb-header justify-content-between">
              <div className="left-content">
                <span className="main-content-title mg-b-0 mg-b-lg-1 d-inline-block">
                  {myParam.toUpperCase()}
                </span>
              </div>

              <Breadcrumbs />
            </div>
            <div className="">
              <main>{children}</main>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar sidebar-right sidebar-animate"></div>

      <Footer />
    </>
  );
};

export default Layout;
