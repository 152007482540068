import { useEffect, useState } from "react";
import Paginate from "./Paginate";

const AffiliateTab = (props) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    // User is currently on this page
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(5);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    
    console.info("props : "+props.data);
    let affiliate = [];
    props.data.map((wallet,i) => {
        console.info(wallet);
        if(wallet.wallet_meta_type == 'Affiliate Commission'){
        affiliate[i] = wallet;
        }
    })

    
  const nPages = Math.ceil(affiliate.length / recordsPerPage)
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
  // }

  const nextPage = () => {
    if(currentPage !== nPages) 
        setCurrentPage(currentPage + 1)
  }
  const prevPage = () => {
    if(currentPage !== 1) 
        setCurrentPage(currentPage - 1)
  }

    console.info(affiliate);

  return (
    
      <div className="p-20">
        <div className="">
          <div
            id="aff-datatable_wrapper"
            className="dataTables_wrapper no-footer">
            <table
              className="table table-hover table-bordered text-nowrap border-bottom dataTable no-footer dtr-inline"
              id="aff-datatable"
              role="grid"
              aria-describedby="aff-datatable_info"
              style={{ width: "947px" }}>
              <thead>
                <tr role="row">
                  <th tabIndex="0"
                    rowSpan="1"
                    colSpan="1"
                    style={{ width: "0px", fontWeight : "bold" }}>
                    Transaction Comment
                  </th>
                  <th tabIndex="0"
                    rowSpan="1"
                    colSpan="1"
                    style={{ width: "0px", fontWeight : "bold" }}>
                    Transaction Status
                  </th>
                  <th tabIndex="0"
                    rowSpan="1"
                    colSpan="1"
                    style={{ width: "0px", fontWeight : "bold" }}>
                    Amount
                  </th>
                  <th tabIndex="0"
                    rowSpan="1"
                    colSpan="1" 
                    style={{ width: "0px", fontWeight : "bold" }}>
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
              { affiliate.length <= 0 && 
                <tr className="odd"><td valign="top" colSpan="4" className="dataTables_empty">No data available in table</td></tr>
            }
                { affiliate.map((wall) => {
                return <tr className="odd">
                  <td>{wall.transaction_comment}</td>
                  <td>{wall.transaction_status}</td>
                  <td>{wall.amount}</td>
                  <td>{new Date(wall.created_at).toLocaleDateString(undefined, options)}</td>
                </tr>
                }) }
              </tbody>
            </table>
            { affiliate.length > 0 && 
            <Paginate pageNumbers = {pageNumbers}
                    nPages = { nPages }
                    currentPage = { currentPage } 
                    setCurrentPage = { setCurrentPage }
                    nextPage = { nextPage }
                    prevPage = { prevPage }
                />
            }
          </div>
        </div>
      </div>
  );
};

export default AffiliateTab;